<header id="page-topbar">
    <div class="layout-width">
        <div class="navbar-header">
            <div class="d-flex">
                <!-- LOGO -->
                <div class="navbar-brand-box horizontal-logo">
                    <a routerLink="/" class="logo logo-dark">
                        <span class="logo-sm">
                            <img src="assets/images/logo-sm.png" alt="" height="22">
                        </span>
                        <span class="logo-lg">
                            <img src="assets/images/logo-dark.png" alt="" height="25">
                        </span>
                    </a>

                    <a routerLink="/" class="logo logo-light">
                        <span class="logo-sm">
                            <img src="assets/images/logo-sm.png" alt="" height="22">
                        </span>
                        <span class="logo-lg">
                            <img src="assets/images/logo-light.png" alt="" height="17">
                        </span>
                    </a>
                </div>

                <button type="button" class="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
                    id="topnav-hamburger-icon">
                    <span class="hamburger-icon" (click)="toggleMobileMenu($event)">
                        <span></span>
                        <span></span>
                        <span></span>
                    </span>
                </button>

                <button type="button" class="btn btn-sm px-3 fs-15 header-item user-name-text d-none d-md-block"
                    (click)="showDialog()">
                    <span class="bi bi-search me-2"></span> Buscar en Valora...
                </button>
            </div>

            <div class="d-flex align-items-center">
                <div class="d-md-none topbar-head-dropdown header-item">
                    <button type="button" class="btn btn-icon btn-topbar btn-ghost-dark rounded-circle"
                        id="page-header-search-dropdown" (click)="showDialog()">
                        <i class="bi bi-search fs-16"></i>
                    </button>
                </div>

                <div class="dropdown topbar-head-dropdown ms-1 header-item">
                    <button type="button" class="btn btn-icon btn-topbar btn-ghost-dark rounded-circle"
                        (click)="toggleFixedPosition()">
                        <i class='bi bi-star fs-18'></i>
                    </button>
                    <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end  p-0"
                        [ngClass]="{'show': isFixedPosition}">
                        <div class="p-3 border-top-0 border-start-0 border-end-0 border-dashed border">
                            <div class="row align-items-center">
                                <div class="col">
                                    <h6 class="m-0 fw-semibold fs-15">Favoritos</h6>
                                </div>
                                <div class="col-auto">
                                    <a routerLink="/" class="btn btn-sm btn-soft-primary"
                                        (click)="toggleFixedPosition()"> Ver Favoritos
                                        <i class="ri-arrow-right-s-line align-middle"></i></a>
                                </div>
                            </div>
                        </div>
                        <div class="p-2">
                            <div class="row g-0">
                                <div class="col-12 my-2">
                                    <a [routerLink]="['/inventory/productreader']"
                                        class="btn btn-sm btn-soft-info text-nowrap w-100"
                                        (click)="toggleFixedPosition()">
                                        Lector de Productos
                                        <i class="ri-arrow-right-s-line align-middle"></i></a>
                                </div>
                                <!-- <div class="col">
                                    <a class="dropdown-icon-item" href="javascript:void(0);">
                                        <img src="assets/images/brands/img-3.png" alt="Github">
                                    </a>
                                </div>
                                <div class="col">
                                    <a class="dropdown-icon-item" href="javascript:void(0);">
                                        <img src="assets/images/brands/img-13.png" alt="Github">
                                    </a>
                                </div> -->
                            </div>

                            <!-- <div class="row g-0">
                                <div class="col">
                                    <a class="dropdown-icon-item" href="javascript:void(0);">
                                        <img src="assets/images/brands/img-5.png" alt="Github">
                                    </a>
                                </div>
                                <div class="col">
                                    <a class="dropdown-icon-item" href="javascript:void(0);">
                                        <img src="assets/images/brands/img-6.png" alt="Github">
                                    </a>
                                </div>
                                <div class="col">
                                    <a class="dropdown-icon-item" href="javascript:void(0);">
                                        <img src="assets/images/brands/img-4.png" alt="Github">
                                    </a>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>

                <!-- <div class="dropdown topbar-head-dropdown ms-1 header-item">
                    <button type="button" class="btn btn-icon btn-topbar btn-ghost-dark rounded-circle"
                        id="page-header-cart-dropdown" (click)="toggleCart()">
                        <i class='bi bi-bag fs-18'></i>
                        <p-badge
                            class="position-absolute topbar-badge cartitem-badge fs-10 translate-middle badge rounded-pill bg-info">{{
                            badgeValue }}</p-badge>
                    </button>
                    <div class="dropdown-menu dropdown-menu-xl dropdown-menu-end p-0 dropdown-menu-cart"
                        [ngClass]="{'show': cartVisible}">
                        <div class=" p-3 border-top-0 border-start-0 border-end-0 border-dashed border">
                            <div class="row align-items-center">
                                <div class="col">
                                    <h6 class="m-0 fs-16 fw-semibold">My Cart</h6>
                                </div>
                                <div class="col-auto">
                                    <span class="badge bg-info-subtle text-info fs-13"><span class="cartitem-badge">{{
                                            badgeValue }}</span>
                                        items</span>
                                </div>
                            </div>
                        </div>
                        <p-scrollPanel [style]="{width: '100%'}" styleClass="cart">
                            <div class="p-2">
                                @if(isCartEmpty){
                                <div class="text-center empty-cart" id="empty-cart">
                                    <div class="avatar-md mx-auto my-3">
                                        <div class="avatar-title bg-info-subtle text-info fs-36 rounded-circle">
                                            <i class='bx bx-cart'></i>
                                        </div>
                                    </div>
                                    <h5 class="mb-3">Your Cart is Empty!</h5>
                                    <a href="javascript:void(0);" class="btn btn-success w-md mb-3">Shop
                                        Now</a>
                                </div>}
                                @for (item of cart; track $index) {
                                <div class="d-block dropdown-item dropdown-item-cart text-wrap px-3 py-2">
                                    <div class="d-flex align-items-center">
                                        <img src="{{item.img}}" class="me-3 rounded-circle avatar-sm p-2 bg-light"
                                            alt="user-pic">
                                        <div class="flex-grow-1">
                                            <h6 class="mt-0 mb-2 fs-15">
                                                <a href="javascript:void(0);" class="text-reset">{{item.name}}</a>
                                            </h6>
                                            <p class="mb-0 fs-13 text-muted">
                                                Quantity: <span>{{item.quantity}}</span>
                                            </p>
                                        </div>
                                        <div class="px-2">
                                            <h5 class="m-0 fw-normal"><span
                                                    class="cart-item-price">{{item.price}}</span></h5>
                                        </div>
                                        <div class="ps-2">
                                            <button type="button"
                                                class="btn btn-icon btn-sm btn-ghost-danger remove-item-btn"
                                                (click)="hideCartItem($index)"><i
                                                    class="ri-close-fill fs-16"></i></button>
                                        </div>
                                    </div>
                                </div>}
                            </div>
                        </p-scrollPanel>
                        @if(!isCartEmpty){
                        <div class="p-3 border-bottom-0 border-start-0 border-end-0 border-dashed border"
                            id="checkout-elem">
                            <div class="d-flex justify-content-between align-items-center pb-3">
                                <h5 class="m-0 text-muted">Total:</h5>
                                <div class="px-2">
                                    <h5 class="m-0" id="cart-item-total">$1258.58</h5>
                                </div>
                            </div>

                            <a href="javascript:void(0);" class="btn btn-success text-center w-100">
                                Checkout
                            </a>
                        </div>}
                    </div>
                </div> -->

                <div class="ms-1 header-item d-none d-sm-flex dropdown-hover-end">
                    <button type="button" class="btn btn-icon btn-topbar btn-ghost-dark rounded-circle"
                        data-toggle="fullscreen" (click)="fullscreen()">
                        <i class='bi bi-arrows-fullscreen fs-16'></i>
                    </button>
                </div>

                <div class="dropdown topbar-head-dropdown ms-1 header-item">
                    <button type="button" class="btn btn-icon btn-topbar btn-ghost-dark rounded-circle"
                        (click)="toggleChange()">
                        <i class="bi bi-sun align-middle fs-20"></i>
                    </button>
                    <div class="dropdown-menu p-2 dropdown-menu-end" [ngClass]="{'show':changesMode }">
                        <a href="javascript:void(0);" class="dropdown-item" (click)="changeMode('light')"
                            (click)="toggleChange()"><i class="bi bi-sun align-middle me-2"></i>Modo Claro
                            (Predeterminado)</a>
                        <a href="javascript:void(0);" class="dropdown-item" (click)="changeMode('dark')"
                            (click)="toggleChange()"><i class="bi bi-moon align-middle me-2"></i>Modo Noche</a>
                        <a href="javascript:void(0);" class="dropdown-item" (click)="changeMode('light')"
                            (click)="toggleChange()"><i class="bi bi-moon-stars align-middle me-2"></i> Automático
                            (Predeterminado por Sistema)</a>
                    </div>
                </div>

                <!-- <div class="dropdown topbar-head-dropdown ms-1 header-item" id="notificationDropdown">
                    <button type="button" class="btn btn-icon btn-topbar btn-ghost-dark rounded-circle"
                        id="page-header-notifications-dropdown" (click)="ToggleNotification()">
                        <i class='bi bi-bell fs-18'></i>
                        <span
                            class="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger"><span
                                class="notification-badge">4</span><span class="visually-hidden">unread
                                messages</span></span>
                    </button>
                    <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                        [ngClass]="{'show' : notiVisible}">
                        <div class="dropdown-head rounded-top">
                            <div class="p-3 border-bottom border-bottom-dashed">
                                <div class="row align-items-center">
                                    <div class="col">
                                        <h6 class="mb-0 fs-16 fw-semibold"> Notifications <span
                                                class="badge bg-danger-subtle text-danger fs-13 notification-badge">
                                                4</span></h6>
                                        <p class="fs-14 text-muted mt-1 mb-0">You have <span
                                                class="fw-semibold notification-unread">3</span> unread messages</p>
                                    </div>
                                    <div class="col-auto dropdown">
                                        <a href="javascript:void(0);" data-bs-toggle="dropdown"
                                            class="link-secondar2 fs-15"><i class="bi bi-three-dots-vertical"></i></a>
                                        <ul class="dropdown-menu">
                                            <li><a class="dropdown-item" href="javascript:void(0);">All Clear</a></li>
                                            <li><a class="dropdown-item" href="javascript:void(0);">Mark all as read</a>
                                            </li>
                                            <li><a class="dropdown-item" href="javascript:void(0);">Archive All</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        @if(!notificationActionsVisible){
                        <div class="py-2 ps-2" id="notificationItemsTabContent">
                            <p-scrollPanel [style]="{'width': '300px', 'height':'300px','max-height': 'auto'}">
                                @for (data of notifications; track $index) {
                                <div>
                                    @if(data.items.length > 0){
                                    <h6 class="text-overflow text-muted fs-sm my-2 text-uppercase notification-title">
                                        {{data.title}}</h6>
                                    }
                                    @for (item of data.items; track $index) {
                                    <div class="text-reset notification-item d-block dropdown-item position-relative"
                                        [ngClass]="data.title == 'New'?'unread-message':''">
                                        <div class="d-flex">

                                            @if(item.avatar){
                                            <div class="position-relative me-3 flex-shrink-0">
                                                <img src="{{item.avatar}}" class="rounded-circle avatar-xs"
                                                    alt="user-pic">
                                                <span
                                                    class="active-badge position-absolute start-100 translate-middle p-1 bg-success rounded-circle">
                                                    <span class="visually-hidden">{{item.activeBadge}}</span>
                                                </span>
                                            </div>}@else {
                                            <div class="avatar-xs me-3 flex-shrink-0">
                                                <span class="avatar-title {{item.background}} rounded-circle fs-lg">
                                                    <i class="{{item.icon}}"></i>
                                                </span>
                                            </div>
                                            }
                                            <div class="flex-grow-1">
                                                <a href="javascript:void(0);" class="stretched-link">
                                                    @if(item.name){
                                                    <a href="javascript:void(0);" class="stretched-link">
                                                        <h6 class="mt-0 mb-1 fs-md fw-semibold">{{item.name}}</h6>
                                                    </a>}@else {
                                                    <h6 class="mt-0 fs-md mb-2 lh-base">
                                                        {{item.text}}
                                                    </h6>
                                                    }
                                                </a>
                                                @if(item.name){
                                                <div class="fs-sm text-muted">
                                                    <p class="mb-1">{{item.text}}</p>
                                                </div>}
                                                <p class="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                                    <span><i class="mdi mdi-clock-outline"></i>
                                                        {{item.timestamp}}</span>
                                                </p>
                                            </div>
                                            <div class="px-2 fs-base">
                                                <div class="form-check notification-check">
                                                    <input class="form-check-input" type="checkbox" value="{{item.id}}"
                                                        [(ngModel)]="item.state"
                                                        (change)="onCheckboxChange($event,$index)"
                                                        id="{{item.checkboxId}}">
                                                    <label class="form-check-label" for="{{item.checkboxId}}"></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                                </div>}
                            </p-scrollPanel>
                            <div class="notification-actions" id="notification-actions" style=" z-index: 9999;">
                                <div class=" d-flex text-muted justify-content-center align-items-center">
                                    Select <div id="select-content" class="text-body fw-semibold px-1">
                                        {{checkedValGet.length}}</div> Result <button type="button"
                                        class="btn btn-link link-danger p-0 ms-2" (click)="showModal()">Remove</button>
                                </div>
                            </div>
                            <div class="empty-notification-elem text-center px-4 d-none">
                                <div class="mt-3 avatar-md mx-auto">
                                    <div class="avatar-title bg-info-subtle text-info fs-24 rounded-circle">
                                        <i class="bi bi-bell"></i>
                                    </div>
                                </div>
                                <div class="pb-3 mt-2">
                                    <h6 class="fs-lg fw-semibold lh-base">Hey! You have no any notifications </h6>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div> -->

                <div class="dropdown ms-sm-3 topbar-head-dropdown dropdown-hover-end header-item topbar-user">
                    <button type="button" class="btn" id="page-header-user-dropdown" data-bs-toggle="dropdown"
                        (click)="toggleDropdown()" aria-haspopup="true" aria-expanded="false">
                        <span class="d-flex align-items-center">
                            <img class="rounded-circle header-profile-user" src="{{imgsURL}}full/{{photo}}"
                                alt="Header Avatar">
                            <span class="text-start ms-xl-2">
                                <span class="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{{name}}
                                    {{lastname}}</span>
                                <span class="d-none d-xl-block ms-1 fs-13 user-name-sub-text">{{position}}</span>
                            </span>
                        </span>
                    </button>
                    <div class="dropdown-menu dropdown-menu-end" [ngClass]="{ 'show': isDropdownOpen }">
                        <!-- item-->
                        <h6 class="dropdown-header p-3">Bienvenido {{name}}!</h6>
                        <a class="dropdown-item" routerLink="/extrapages/profile" (click)="toggleDropdown()"><i
                                class="bi bi-person-circle text-muted fs-15 align-middle me-1"></i> <span
                                class="align-middle">Perfil</span></a>
                        <!-- <a class="dropdown-item" routerLink="/extrapages/calender"><i
                                class="bi bi-cart4 text-muted fs-15 align-middle me-1"></i> <span
                                class="align-middle">Order Track</span></a>
                        <a class="dropdown-item" routerLink="/products/product-list"><i
                                class="bi bi-box-seam text-muted fs-15 align-middle me-1"></i> <span
                                class="align-middle">Products</span></a>
                        <a class="dropdown-item" href="" target="_blank"><span
                                class="badge bg-success-subtle text-success float-end ms-2">New</span><i
                                class="bi bi-cassette text-muted fs-15 align-middle me-1"></i> <span
                                class="align-middle">Frontend</span></a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" routerLink="/extrapages/setting"><i
                                class="bi bi-gear text-muted fs-15 align-middle me-1"></i> <span
                                class="align-middle">Settings</span></a> -->
                        <a class="dropdown-item" (click)="logout()" (click)="toggleDropdown()"><i
                                class="bi bi-box-arrow-right text-muted fs-15 align-middle me-1"></i> <span
                                class="align-middle" data-key="t-logout">Salir</span></a>
                    </div>
                </div>
            </div>

        </div>
    </div>
</header>

<!-- Modal -->
<p-dialog [modal]="true" id="searchModal" [(visible)]="searchmodal" position="top" [dismissableMask]="true"
    [style]="{width: '50vw'}" [showHeader]="false" styleClass="modal-lg">
    <div class="modal-body p-0">
        <div class="position-relative w-100">
            <input type="text" class="form-control form-control-lg border-2" placeholder="Buscar en Valora..."
                autocomplete="off" id="search-options" value="" (keyup)="Search()">
            <span class="bi bi-search search-widget-icon fs-17"></span>
            <a href="javascript:void(0);" (click)="closeBtn()"
                class="search-widget-icon fs-14 link-secondary text-decoration-underline search-widget-icon-close d-none"
                id="search-close-options">Clear</a>
        </div>
    </div>
    <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 overflow-hidden" id="search-dropdown">
        <div class="dropdown-head rounded-top">
            <div class="p-3">
                <div class="row align-items-center">
                    <div class="col">
                        <h6 class="m-0 fs-14 text-muted fw-semibold"> RECENT SEARCHES </h6>
                    </div>
                </div>
            </div>

            <div class="dropdown-item bg-transparent text-wrap">
                <a routerLink="/" class="btn btn-soft-secondary btn-sm btn-rounded">how to setup <i
                        class="mdi mdi-magnify ms-1 align-middle"></i></a>
                <a routerLink="/" class="btn btn-soft-secondary btn-sm btn-rounded">buttons <i
                        class="mdi mdi-magnify ms-1 align-middle"></i></a>
            </div>
        </div>

        <p-scrollPanel [style]="{width: '100%', height: '300px'}" styleClass="pe-2 ps-3 mt-3">
            <div class="list-group list-group-flush border-dashed">
                <div class="notification-group-list">
                    <h5 class="text-overflow text-muted fs-13 mb-2 mt-3 text-uppercase notification-title">
                        Apps Pages</h5>
                    <a href="javascript:void(0);" class="list-group-item dropdown-item notify-item"><i
                            class="bi bi-speedometer2 me-2"></i> <span>Analytics Dashboard</span></a>
                    <a href="javascript:void(0);" class="list-group-item dropdown-item notify-item"><i
                            class="bi bi-ticket-detailed me-2"></i> <span>Support Tickets</span></a>
                </div>

                <div class="notification-group-list">
                    <h5 class="text-overflow text-muted fs-13 mb-2 mt-3 text-uppercase notification-title">
                        Links</h5>
                    <a href="javascript:void(0);" class="list-group-item dropdown-item notify-item"><i
                            class="bi bi-link-45deg me-2 align-middle"></i>
                        <span>www.valoracatalog.com</span></a>
                </div>

                <div class="notification-group-list">
                    <h5 class="text-overflow text-muted fs-13 mb-2 mt-3 text-uppercase notification-title">
                        People</h5>
                    <a href="javascript:void(0);" class="list-group-item dropdown-item notify-item">
                        <div class="d-flex align-items-center">
                            <img alt="" class="avatar-xs rounded-circle flex-shrink-0 me-2" />
                            <div>
                                <h6 class="mb-0">Ayaan Bowen</h6>
                                <span class="fs-12 text-muted">React Developer</span>
                            </div>
                        </div>
                    </a>
                    <a href="javascript:void(0);" class="list-group-item dropdown-item notify-item">
                        <div class="d-flex align-items-center">
                            <img alt="" class="avatar-xs rounded-circle flex-shrink-0 me-2" />
                            <div>
                                <h6 class="mb-0">Alexander Kristi</h6>
                                <span class="fs-12 text-muted">React Developer</span>
                            </div>
                        </div>
                    </a>
                    <a href="javascript:void(0);" class="list-group-item dropdown-item notify-item">
                        <div class="d-flex align-items-center">
                            <img alt="" class="avatar-xs rounded-circle flex-shrink-0 me-2" />
                            <div>
                                <h6 class="mb-0">Alan Carla</h6>
                                <span class="fs-12 text-muted">React Developer</span>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </p-scrollPanel>
    </div>
    <!-- </p-dialog> -->

</p-dialog>

<!-- removeNotificationModal -->
<p-dialog [modal]="true" [(visible)]="removemodal" [dismissableMask]="true" [showHeader]="false"
    id="removeNotificationModal" styleClass="" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                    id="NotificationModalbtn-close"></button>
            </div>
            <div class="modal-body p-md-5">
                <div class="text-center">
                    <div class="text-danger">
                        <i class="bi bi-trash display-4"></i>
                    </div>
                    <div class="mt-4 fs-15">
                        <h4 class="mb-1">Are you sure ?</h4>
                        <p class="text-muted mx-4 mb-0">Are you sure you want to remove this Notification ?</p>
                    </div>
                </div>
                <div class="d-flex gap-2 justify-content-center mt-4 mb-2">
                    <button type="button" class="btn w-sm btn-light" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn w-sm btn-danger" id="delete-notification"
                        (click)="notificationDelete()">Yes, Delete It!</button>
                </div>
            </div>

        </div>
    </div>
</p-dialog>